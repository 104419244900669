import * as Sentry from '@sentry/vue';
import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import routes from './routes';
import { SENTRY, VITE_GIT_BRANCH, VITE_GIT_COMMIT } from './scripts/Configuration.js';

const app = createApp(App);

const router = createRouter({
  history: createWebHistory(),
  routes,
});

if (VITE_GIT_BRANCH !== 'local') {
  Sentry.init({
    app,
    release: VITE_GIT_COMMIT,
    environment: VITE_GIT_BRANCH,
    dsn: `https://${SENTRY.DSN}.ingest.sentry.io/${SENTRY.PROJECT_ID}`,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: SENTRY.TRACING_ORIGINS,
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    sendDefaultPii: true,
  });
}
app.use(router);

app.mount('#app');
